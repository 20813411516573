import React from "react";
import Spinner from "../utils/Spinner";
import { MainContext } from "../App";

export default function Profile(props) {
    const { setMessage, serverUrl, headers, setPageTitle } = React.useContext(MainContext);
    let [states, setStates] = React.useState([]);
    let [linkCategory, setLinkCategory] = React.useState({
        'github': 0,
        'linkedin': 0,
        'hackerrank': 0,
    })
    function updateProfile(event) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        event.preventDefault();
        let displayImg = document.querySelector('#display-img');
        let resume = document.querySelector('#resume');
        let formData = new FormData();
        formData.append('first_name', document.getElementById('first-name').value);
        formData.append('last_name', document.getElementById('last-name').value);
        formData.append('contact_no', document.getElementById('contact-no').value);
        formData.append('date_of_birth', document.getElementById('date-of-birth').value);
        formData.append('gender', document.getElementById('gender').value);
        formData.append('state', document.getElementById('state').value);
        let links = [];
        var slides = document.getElementsByClassName("user-link");
        for (var i = 0; i < slides.length; i++) {
            links.push(JSON.stringify({
                'category': slides[i].id.split('-')[1],
                'link': slides[i].value,
            }))
        }
        formData.append('user_link', links);
        formData.append("display_img", displayImg.files[0]);
        formData.append("resume", resume.files[0]);

        fetch(serverUrl + 'api/user/profile', {
            method: 'POST',
            headers: {
                'Authorization': headers['Authorization'],
            },
            body: formData,
        }).then(resp => resp.json()).then(resp => {
            if (resp.data) {
                setMessage(resp.message);
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000);
            } else {

                setMessage(resp.error);
            }
        })
    }
    React.useEffect(() => {
        setPageTitle('View or update tour profile')
        fetch(serverUrl + 'api/metadata/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'category': 'user_link',
                'location': true
            })
        }).then(resp => resp.json()).then(resp => {
            resp.data.category.forEach(element => {
                element.forEach(i => {
                    if (i.name in linkCategory) {
                        linkCategory[i.name] = i.id;
                    }
                });
                setLinkCategory(linkCategory)
            });
            resp.data.location.forEach(element => {
                if (element.id === "0") {
                    setStates(element.states);
                }
            });
        }).then(() => {
            fetch(serverUrl + 'api/user/profile', {
                method: 'GET',
                headers: headers,
            }).then(resp => resp.json()).then(resp => {
                if (resp.error) {
                    setMessage(resp.message ? resp.message : resp.error.message ? resp.error.message : resp.error)
                }

                document.getElementById('email').value = resp.data.user.email;
                document.getElementById('email').disabled = true;
                document.getElementById('first-name').value = resp.data.user.first_name;
                document.getElementById('last-name').value = resp.data.user.last_name;
                document.getElementById('contact-no').value = resp.data.user.contact_no;
                if (resp.data.address.state) {
                    document.getElementById('state').value = resp.data.address.state;
                }
                if (resp.data.user.date_of_birth) {
                    document.getElementById('date-of-birth').value = resp.data.user.date_of_birth.split('T')[0];
                }
                if (resp.data.user.gender) {
                    document.getElementById('gender').value = resp.data.user.gender;
                }
                // if (cookies.get('user')['profileLocked']) {
                //     document.getElementById('first-name').disabled = true;
                //     document.getElementById('last-name').disabled = true;
                //     document.getElementById('contact-no').disabled = true;
                //     document.getElementById('state').disabled = true;
                //     document.getElementById('date-of-birth').disabled = true;
                //     document.getElementById('gender').disabled = true;
                // }
                resp.data.user_link.forEach(element => {
                    let itemId = 'link-' + element['category'];
                    if (!document.getElementById(itemId)) {
                        itemId = 'link-' + element.category;
                    }
                    document.getElementById(itemId).value = element.link;
                });
            });
        })

    }, []);
    return <React.Fragment>
        {localStorage.getItem('elfAcademyprofileComplete') === true ?
            <center>
                <div className="alert alert-sm alert-warning" style={{ 'width': '50%', 'height': '50%' }}>
                    Please update your profile to continue...
                </div>
            </center> : localStorage.getItem('elfAcademyprofileLocked') === true && <center>
                <div className="alert alert-sm alert-danger" style={{ 'width': '50%', 'height': '50%' }}>
                    You cannot further update your profile.
                </div>
            </center>}
        <form className="container-fluid" onSubmit={(event) => updateProfile(event)}>
            <div className="card">
                <div className="card-body user">
                    <div className="d-flex row mb-3">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <label className="form-label">First name</label>
                            <input type="text" className="form-control" id="first-name"
                                placeholder="First Name" required />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Last name</label>
                            <input type="text" className="form-control" id="last-name"
                                placeholder="Last Name" required />
                        </div>
                    </div>
                    <div className="d-flex row mb-3">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <label className="form-label">Email address</label>
                            <input type="email" className="form-control"
                                placeholder="Email Address" id="email" />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Contact number</label>
                            <input type="number" className="form-control" id="contact-no"
                                placeholder="Contact Number" required />
                        </div>
                    </div>
                    <div className="d-flex row mb-3">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <label className="form-label">Date of birth</label>
                            <input type="date" className="form-control" id="date-of-birth" required />
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">Gender</label>
                            <select className="form-control" id="gender" required>
                                <option value="">Choose gender</option>
                                <option value="m">Male</option>
                                <option value="f">Female</option>
                                <option value="o">other</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex row mb-3">
                        <div className="col-sm-6 mb-3 mb-sm-0">
                            <label className="form-label">Country</label>
                            <select className="form-control" disabled>
                                <option value="">India</option>
                            </select>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label">State</label>
                            <select className="form-control" id="state" required>
                                <option value="">Choose state</option>
                                {states.map(state => {
                                    return <option value={state.id} key={state.id}>{state.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex row mb-3">
                        <label className="form-label">Display image</label>
                        <input type="file" className="form-control" id="display-img" />
                        <small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Do not upload if no changes to diplay image</small>
                    </div>
                    <div className="d-flex row mb-3">
                        <label className="form-label">Current resume(optional)</label>
                        <input type="file" className="form-control" id="resume" />
                        <small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Do not upload if no changes to resume</small>
                    </div>
                    <div className="d-flex row mb-3">
                        <label className="form-label">Link to your Linkedin profile(optional)</label>
                        <input type="url" className="form-control user-link" id={'link-' + linkCategory['linkedin']}
                            placeholder="LinkedIn URL (optional)" />
                    </div>
                    <div className="d-flex row mb-3">
                        <label className="form-label">Link to your Github profile(optional)</label>
                        <input type="url" className="form-control user-link" id={'link-' + linkCategory['github']}
                            placeholder="Github URL (optional)" />
                    </div>
                    <div className="d-flex row mb-3">
                        <label className="form-label">Link to your Hackerrank profile(optional)</label>
                        <input type="url" className="form-control user-link" id={'link-' + linkCategory['hackerrank']}
                            placeholder="HackerRank URL (optional)" />
                    </div>
                    <br />
                    <div className="mb-3">
                        <input type="checkbox" id="terms" required />
                        &nbsp;&nbsp;&nbsp;
                        <label className="form-check-label" htmlFor="terms">
                            <strong>By clicking this checkbox, I agree to the</strong>
                            &nbsp;
                            <a target="_blank" rel="noreferrer"
                                href={serverUrl + 'elf-academy/terms-and-conditions'}>
                                <i>terms & conditions.</i>
                            </a>
                        </label>
                    </div>
                </div>
            </div>
            <center>
                <button type="submit" className="btn btn-success">
                    Update my profile
                </button>
            </center>
        </form>
    </React.Fragment >
}