import React from "react";
import { MainContext } from "../App";

export default function Learn() {
    const { setMessage, serverUrl, headers, setPageTitle } = React.useContext(MainContext);

    React.useEffect(() => {
        setPageTitle()
    })
    return <React.Fragment>


    </React.Fragment>
}