import React, { useState, useEffect } from 'react';
import { MainContext } from '../App';
import Spinner from '../utils/Spinner';

const Forum = () => {
    const { setMessage, serverUrl, headers, setPageTitle } = React.useContext(MainContext);
    const [questions, setQuestions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);

    useEffect(() => {
        fetchQuestions();
        setPageTitle('Elf Academy Forum');
    }, []);

    const fetchQuestions = async () => {
        try {
            fetch(serverUrl + 'api/elf-academy/forum-question', {
                method: 'GET',
                headers: headers,
            }).then(response => response.json()).then(response => {
                if (response.data.message) {
                    window.location.href = '/';
                } else {
                    setQuestions(response.data);
                }
                document.getElementById('forum-spinner').style.display = "none";
            });
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleNewQuestionChange = (event) => {
        setNewQuestion(event.target.value);
    };

    const handlePostQuestion = async () => {
        try {
            const response = await fetch(serverUrl + 'api/elf-academy/forum-question/', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ question: newQuestion })
            });
            const data = await response.json();
            setQuestions([...questions, data]);
            setShowModal(false);
            setNewQuestion('');
        } catch (error) {
            console.error('Error posting question:', error);
        }
    };

    const handleQuestionClick = (questionId) => {
        setSelectedQuestionId(questionId === selectedQuestionId ? null : questionId);
    };

    const filteredQuestions = questions?.filter((question) =>
        question.content.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="container mt-4">
            <div className="input-group mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a question..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>
            <button className="btn btn-primary" onClick={() => setShowModal(true)}>
                Post a New Question
            </button>
            <br />
            <center><Spinner display={'block'} id={'forum-spinner'} /></center>
            <br />
            <ul className="list-group mt-4">
                {filteredQuestions.map((question) => (
                    <>
                        <li
                            key={question.id}
                            className="list-group-item"
                            onClick={() => handleQuestionClick(question.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            {question.content}
                        </li>
                        {selectedQuestionId === question.id && (
                            <div className="accordion-content">
                                <div className="container mt-4">
                                    <h2>Answers</h2>
                                    <div className="list-group">
                                        {question.answers.map((answer) => (
                                            <div key={answer.id} className="list-group-item">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <strong className="text-muted"><strong>{answer.created_by}</strong> : {answer.created_at.replace('T', ' ').replace('Z', '').split('.')[0]}</strong>
                                                </div>
                                                <p className="mb-1">{answer.content}</p>
                                                <strong className="text-muted">Comments:</strong>
                                                <ul className="list-group mt-2">
                                                    {answer.comments.map((comment) => (
                                                        <li key={comment.id} className="list-group-item">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <p className="mb-1">{comment.content}</p>
                                                                <small className="text-muted"><strong>{comment.created_by}</strong> : {comment.created_at.replace('T', ' ').replace('Z', '')}</small>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        <br />
                    </>
                ))}
            </ul>

            {showModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Post a New Question</h5>
                                <button type="button" className="close btn btn-sm btn-danger" onClick={() => setShowModal(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="newQuestion">Question</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="newQuestion"
                                        value={newQuestion}
                                        onChange={handleNewQuestionChange}
                                        placeholder="Enter your question"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary" onClick={handlePostQuestion}>
                                    Post Question
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Forum;
