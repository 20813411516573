import React from "react";
import Spinner from "../utils/Spinner";
import { MainContext } from "../App";

export default function Enroll() {
    const { setMessage, serverUrl, headers, setPageTitle } = React.useContext(MainContext);
    let [items, setItems] = React.useState([]);
    let [selectedItem, selectItem] = React.useState(null);

    function submitForm(event) {
        event.preventDefault();
        document.getElementById('enroll-loader').style.display = '';
        if (selectedItem === null) {
            setMessage('Please choose one.')
        } else {
            fetch(serverUrl + 'api/elf-academy/enroll/', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    programme_id: selectedItem
                }),
            }).then(resp => resp.json()).then(resp => {
                if (resp.message || resp.error) {
                    setMessage(resp.message ? resp.message : resp.error.message ? resp.error.message : resp.error);
                } else if (resp.data) {
                    setMessage(resp.message);
                } else {
                    console.log(resp)
                }
            }).then(() => {
                document.getElementById('enroll-loader').style.display = 'none';
            });
        }
    }

    React.useEffect(() => {
        setPageTitle('Enroll to a programme and begin your journey!')
        fetch(serverUrl + 'api/elf-academy/pricing', {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(res => {
            setItems(res.data);
        })
    }, [])
    return <React.Fragment>
        <div className="row justify-content-center" style={{ 'width': '100%' }}>
            {items.length === 0 && <React.Fragment>
                <Spinner id="-" />
            </React.Fragment>}
            <form onSubmit={event => submitForm(event)} method="post" className="p-5 container">
                <div className={"row d-flex justify-content-between"}>
                    {items.map(item => {
                        return <div key={item.programme.name} className={"col-4"}>
                            <div className="card shadow-lg" style={{ minHeight: '1100px' }}>
                                <div className="card-header" key={item.price.id}>
                                    <strong>{item.programme.get_fee}</strong>
                                </div>
                                <div className={selectedItem === item.programme.id ? "card-body border-2 border border-success" : "card-body"}>
                                    <div className="container align-items-center" style={{ 'marginBottom': 0 }}>
                                        <div className="text-center">
                                            <img className="img-fluid"
                                                style={{ "width": "50%" }}
                                                src={item.programme.image_url} alt="..." />
                                        </div>
                                        <div className="container">
                                            <b><h5>{item.programme.name}</h5></b>
                                            <hr />
                                            <p className="card-text">{item.programme.description}</p>
                                            <hr />
                                            <ul className="timeline-widget position-relative">
                                                <li className="timeline-item d-flex position-relative overflow-hidden">
                                                    <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                                        <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                                    </div>
                                                    <div className="timeline-desc fs-3 text-dark mt-n1">
                                                        <span><span className="font-semibold">Individual</span> training</span>
                                                    </div>
                                                </li>
                                                <li className="timeline-item d-flex position-relative overflow-hidden">
                                                    <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                                        <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                                    </div>
                                                    <div className="timeline-desc fs-3 text-dark mt-n1">
                                                        <span><span className="font-semibold">Interview</span> tips and mock rounds</span>
                                                    </div>
                                                </li>
                                                <li className="timeline-item d-flex position-relative overflow-hidden">
                                                    <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                                        <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                                    </div>
                                                    <div className="timeline-desc fs-3 text-dark mt-n1">
                                                        <span>Industry expert <span className="font-semibold">Mentor</span></span>
                                                    </div>
                                                </li>
                                                <li className="timeline-item d-flex position-relative overflow-hidden">
                                                    <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                                        <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                                    </div>
                                                    <div className="timeline-desc fs-3 text-dark mt-n1">
                                                        <span>Real life <span className="font-semibold">project experience</span></span>
                                                    </div>
                                                </li>
                                                <li className="timeline-item d-flex position-relative overflow-hidden">
                                                    <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                                        <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                                    </div>
                                                    <div className="timeline-desc  text-dark">
                                                        <span>Free <span className="font-semibold">professional resume</span></span>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                        <center>
                                            <div className="alert alert-success text-center" style={{ 'width': '73%', bottom: '3rem', position: 'absolute' }}>
                                                <strong>
                                                    <h5>
                                                        <span className="mr-2 text-5xl font-extrabold">
                                                            {item.price.get_price.split('.')[0]}</span>/
                                                        <span className="text-gray-500"> one time fee</span>
                                                    </h5>
                                                </strong>
                                            </div>
                                        </center>
                                        <br />
                                        {selectedItem == item.programme.id ?
                                            <button type="button" disabled
                                                className="btn btn-block btn-success"
                                                style={{ 'width': '73%', bottom: '1rem', position: 'absolute' }}>
                                                selected
                                            </button>
                                            : <button type="button"
                                                className="btn btn-block btn-outline-primary"
                                                style={{ 'width': '73%', bottom: '1rem', position: 'absolute' }}
                                                onClick={() => {
                                                    selectItem(item.programme.id);
                                                    window.scrollTo({
                                                        top: document.scrollHeight,
                                                        behavior: 'smooth'
                                                    })
                                                }}>
                                                select
                                            </button>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="input-group mb-3" style={{ 'width': '100%' }}>
                    <div className="input-group-text" style={{ 'width': '100%' }}>
                        <input type="checkbox" required />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <i>I agree to the terms & conditions found <a target="_blank" href={serverUrl + 'elf-academy/terms-and-conditions'}>here.</a></i>
                    </div>
                </div>
                <button type="submit" className="btn btn-success btn-icon-split"
                    style={{ 'width': '100%' }}>

                    <span className="text-center " style={{ 'marginTop': '0.4rem' }}>
                        <Spinner id="enroll-loader" />
                        <i className="fa fa-paper-plane"></i>
                    </span>
                    <span className="text">Enroll</span>
                </button>
            </form>
        </div>
    </React.Fragment>;
}