import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Login from './Login';

const root = ReactDOM.createRoot(document.getElementById('main-wrapper'));
let serverUrl = 'https://server.elvesonthecloud.com/';
// if (!process.env.REACT_APP_PRODUCTION) {
//   serverUrl = 'http://localhost:8000/'
// }
let component = <App serverUrl={serverUrl} />;
if (!localStorage.getItem('elfAcademyToken')) {
  // if (localStorage.getItem('elfAcademyToken')) {
  component = <Login serverUrl={serverUrl} />;
}
root.render(component);
