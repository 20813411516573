import React from "react";
import { MainContext } from "../App";
import Spinner from "../utils/Spinner";

export default function GettingStarted() {
    const { setPageTitle, serverUrl, headers } = React.useContext(MainContext);
    let [items, setItems] = React.useState([]);
    let [steps, setSteps] = React.useState([]);
    console.log(items.length)
    React.useEffect(() => {
        setPageTitle('How to get started?')
        fetch(serverUrl + 'api/elf-academy/getting-started', {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(res => {
            if (res.data.pay_first) {
                window.location.href = '/';
            } else if (!res.data.steps) {
                window.location.href = '/';
            } else {
                setItems(res.data.requirements);
                setSteps(res.data.steps);
            }
        })
    }, []);
    return <React.Fragment>
        <nav className="navbar  navbar-dark bg-dark">
            <div className="container">
                <a className="navbar-brand" href="#">Getting Started Guide</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="#step1">Step 1</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#step2">Step 2</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#step3">Step 3</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#step4">Step 4</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        {items.length > 0 ? <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="mt-4">How to Get Started</h1>
                    <p className="lead">Follow these simple steps to get started with our program.</p>
                    {steps.map((step, idx) => {
                        return <div className="my-4">
                            <div className="step-number"><strong>Step {idx + 1}</strong></div>
                            <div className="step-description"><i>{step.title}</i></div>
                            <p>{step.description}</p>
                            {step.link && <strong>Visit this <a href={step.link} target="_blank" rel="noopener noreferrer"><b>link</b></a> to learn more.</strong>}
                        </div>
                    })}
                    <br />
                    <hr />
                    <br />
                    <h1 className="mt-4">Programme Requirements</h1>
                    {items.map(item => {
                        return <div className="my-4">
                            <div className="step-number"><strong>{item.name}</strong></div>
                            <div className="step-description">{item.description}</div>
                            <br />
                            {item.url && <b>Visit this <a href={item.url} target="_blank" rel="noopener noreferrer">link</a> to download.</b>}
                            <hr />
                        </div>
                    })}
                </div>
            </div>
        </div> : <React.Fragment>
            <br />
            <center><Spinner display={'block'} id={'gs-spinner'} /></center>
            <br /></React.Fragment>}
    </React.Fragment>
}