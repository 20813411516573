import React, { useState, useEffect } from 'react';
import { MainContext } from '../App';
import Spinner from '../utils/Spinner';
import Project from './Project';

const Programme = () => {
    const { setPageTitle, serverUrl, headers } = React.useContext(MainContext);
    const [programme, setProgramme] = useState(null);

    useEffect(() => {
        fetch(serverUrl + 'api/elf-academy/programme', {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(res => {
            setProgramme(res.data.programme);
            setPageTitle(res.data.name);
        });
    }, []);


    return (
        <React.Fragment>
            {programme != null ? <React.Fragment >
                <div className='row'>
                    <div className="col-md-6">
                        <div className="card mb-4">
                            <br />
                            <center><img src={programme.image_url} className="card-img-top" alt="Programme" style={{ width: '200px' }} /></center>
                            <div className="card-body">
                                <h1> {programme.name}</h1>
                                <p className='card-text'>{programme.description}</p>
                                <br />
                                <a href={programme.exam} className="btn btn-danger btn-sm" style={{ width: '100%' }}
                                    target="_blank" rel="noopener noreferrer" disabled="true">
                                    Attempt the exam
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card shadow-lg container">
                            <h3 className="mt-4">Programme Requirements</h3>
                            <br />
                            <hr />
                            {programme.requirements.map(item => {
                                return <div className="container">
                                    <div className="step-number" style={{ textTransform: 'uppercase' }}><strong>{item.name}</strong></div>
                                    <br />
                                    <div className="step-description">{item.description}</div>
                                    <br />
                                    {item.url && <b>Visit this <a href={item.url} target="_blank" rel="noopener noreferrer">link</a> to download.</b>}
                                    <hr />
                                </div>
                            })}
                        </div >
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6">
                        <div className="card shadow-lg container">
                            <h3 className="mt-4">Study Material</h3>
                            <br />
                            <hr />
                            {programme.resources.map(item => {
                                return <div className="container">
                                    <div className="step-number" style={{ textTransform: 'uppercase' }}><strong>{item.name}</strong></div>
                                    <br />
                                    <div className="step-description">{item.description}</div>
                                    <br />
                                    {item.file_url && <a href={item.file_url} className="col-sm-3 btn btn-info btn-sm" target="_blank" rel="noopener noreferrer">Study material</a>}
                                    <hr />
                                </div>
                            })}
                        </div >
                    </div>
                    <div className="col-md-6">
                        <div className="card shadow-lg container">
                            <h3 className="mt-4">Choose project</h3>
                            <br />
                            <hr />
                            <Project data={programme.projects} />
                        </div>
                    </div>
                </div>



            </React.Fragment > : <Spinner display='block' />
            }
        </React.Fragment >
    );
};

export default Programme;
