import React, { useState } from 'react';

const ProjectItem = ({ item, index, activeIndex, setActiveIndex }) => {
    const handleToggle = () => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    function selectProject(pId) {

    }
    return (
        <div className='container'>
            <div onClick={handleToggle} className={activeIndex === index ? 'card shadow-lg row d-flex bg-success text-white' : 'card shadow-lg row d-flex'} style={{ cursor: 'pointer', padding: '7px' }}>
                <p><i class="ti ti-arrows-vertical"></i>{item.name}</p>
            </div>
            {activeIndex === index && (
                <div className='card container'>
                    <p>{item.description}</p>
                    <button className='btn btn-info btn-sm' onClick={() => selectProject(item.id)}>Select this project</button>
                    <br />
                </div>
            )}
        </div>
    );
};

const Project = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    return (
        <div className='container'>
            {data.map((item, index) => (
                <ProjectItem
                    key={index}
                    item={item}
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                />
            ))}
        </div>
    );
};

export default Project;
