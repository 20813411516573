import React from "react";
import { MainContext } from "../App";
import Spinner from "../utils/Spinner";

export default function Home() {
    const { setPageTitle, serverUrl, headers } = React.useContext(MainContext);
    let [mentor, setMentor] = React.useState(null);
    let [programme, setProgramme] = React.useState(null);
    let [qrCode, setQrCode] = React.useState(null);
    let [amount, setAmount] = React.useState(null);
    let [message, setMessage] = React.useState(null);
    let [banner, setBanner] = React.useState(null);
    let [session, setSession] = React.useState(null);

    React.useEffect(() => {

        fetch(serverUrl + 'api/elf-academy/', {
            method: 'GET',
            headers: headers
        }).then(res => res.json()).then(res => {
            if (res.enroll_first) {
                window.location.href = '/enroll';
            } else {
                if (res.data.qr_code_url) {
                    setQrCode(res.data.qr_code_url);
                    setAmount(res.data.amount.get_price);
                    setPageTitle('Payment');
                } else if (res.data.programme) {
                    setPageTitle(res.data.programme.name)
                    setMentor(res.data.mentor);
                    setProgramme(res.data.programme);
                    setSession(res.data.session);
                }
                setBanner(res.data.message);
            }
        })
    }, []);
    return <React.Fragment>
        {banner && <React.Fragment>
            <br />
            <center> <Spinner id="temp-Id" display="block" /></center>
            <br />
            <br />
            <div className="alert alert-primary">
                <center> <b>{banner}</b></center>
            </div>
        </React.Fragment>}
        {qrCode ? <center>
            <div className="col-md-4 shadow-lg rounded">
                <div className="card">
                    <br />
                    <div className="container-fluid rounded">
                        <img src={qrCode} className="card-img-top" alt="qr" />
                    </div>
                    <div className="card-body">
                        <p className="card-text">{message}</p>
                        <h5 className="card-title"><b>{amount}</b></h5>
                    </div>
                </div>
            </div>
        </center> : mentor === null ? <React.Fragment>
            <br />
            <center> <Spinner id="tempId" display="block" /></center>
            <br />
            <br />
            <div className="alert alert-primary">
                Please wait while a mentor is assigned to you. It usually takes a day or two.
            </div>
            <div className="alert alert-success">
                Meanwhile, we suggest you go through the "getting started" page found <a href="\getting-started"><b>here</b></a>.
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="alert alert-warning">
                In case this does not happen in a day, please contact our team.
            </div>
        </React.Fragment> : programme && <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="card mb-4">
                        <br />

                        <center><img src={programme.image_url} className="card-img-top" alt="Programme" style={{ width: '200px' }} /></center>
                        <div className="card-body">
                            <h5 className="card-title">{programme.name}</h5>
                            <br />
                            <div className="row d-flex justify-content-center">
                                <a href='\programme' className="col-sm-3 btn btn-primary btn-sm">Learn</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href={session} className="col-sm-3 btn btn-success btn-sm" target="_blank" rel="noopener noreferrer">Training session</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href={programme.exam} className="col-sm-3 btn btn-danger btn-sm" target="_blank" rel="noopener noreferrer" disabled="disabled">Attempt the exam</a>
                            </div>
                            <br />
                            <p className="card-text">{programme.description}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    {mentor.links && <div className="card mb-4">
                        <br />
                        <center><img src={mentor.image_url} className="card-img-top" alt="Programme" style={{ width: '200px' }} /></center>
                        <div className="card-body">
                            <h5 className="card-title">Mentor Information</h5>
                            <p className="card-text"><strong>Specialization:</strong> {mentor.specialization}</p>
                            <p className="card-text"><strong>Organization:</strong> {mentor.organization}</p>
                            <p className="card-text"><strong>Designation:</strong> {mentor.designation}</p>
                            <p className="card-text"><strong>Available:</strong> {mentor.available ? "Yes" : "No"}</p>
                            <div className="card shadow-lg container">
                                <p className="card-text">
                                    <br />
                                    {mentor.bio}
                                    <br />
                                    <br />
                                </p>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <a href={mentor.links.linkedin_url} className="col-sm-3 btn btn-primary btn-lg">
                                    <i className="ti ti-brand-linkedin"></i>Linkedin
                                </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href={mentor.links.hackerrank_url} className="col-sm-5 btn btn-success btn-lg" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-brand-hackerrank">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M19.484 5.667c-1.146 -.904 -3.35 -2.394 -6.497 -3.429c-.484 -.159 -.725 -.238 -1.04 -.238c-.314 0 -.556 .08 -1.04 .238c-3.147 1.035 -5.35 2.525 -6.496 3.43c-.402 .317 -.604 .476 -.797 .816c-.194 .341 -.233 .62 -.309 1.178a33 33 0 0 0 -.305 4.338c0 1.742 .165 3.317 .305 4.338c.076 .558 .115 .837 .309 1.178c.193 .34 .395 .5 .797 .817c1.146 .904 3.35 2.394 6.497 3.429c.483 .159 .725 .238 1.04 .238c.314 0 .555 -.08 1.04 -.238c3.146 -1.035 5.35 -2.525 6.496 -3.43c.402 -.317 .603 -.476 .797 -.816c.194 -.341 .232 -.62 .309 -1.178c.14 -1.021 .305 -2.596 .305 -4.338s-.165 -3.317 -.305 -4.338c-.077 -.558 -.115 -.837 -.309 -1.178s-.395 -.5 -.797 -.817" />
                                        <path d="M9 8v7" />
                                        <path d="M9 12h6" />
                                        <path d="M16 16h-2l1 1z" />
                                        <path d="M8 8h2l-1 -1z" />
                                        <path d="M15 9v7" />
                                    </svg>Hackerrank
                                </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href={mentor.links.website_url} className="col-sm-3 btn btn-info btn-lg" target="_blank" rel="noopener noreferrer">
                                    <i className="ti ti-world"></i>Website
                                </a>
                            </div>
                        </div>
                    </div>}
                </div>

            </div>

        </React.Fragment>}
    </React.Fragment>
}