import React from "react";
import Spinner from "./utils/Spinner";

export default function Login(props) {

    let [message, setMessage] = React.useState('');
    function otp(event) {
        if (document.getElementById('otp').disabled === false) {
            event.preventDefault();
            document.getElementById('login-loader').style.display = '';
            fetch(props.serverUrl + 'api/user/otp-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'email': document.getElementById('email').value,
                    'otp': document.getElementById('otp').value
                }),
            }).then(resp => resp.json()).then(resp => {
                console.log(resp.data)
                if (resp.data) {
                    localStorage.setItem('elfAcademyToken', resp.data.token)
                } else {
                    if (resp.error) {
                        setMessage(resp.error.message)
                    }
                    alert('Failed to authenticate!');
                }
                window.location.reload();
            });
        } else {
            event.preventDefault();
            setMessage('Check your inbox for an OTP.')
            document.getElementById('login-loader').style.display = '';
            fetch(props.serverUrl + 'api/user/otp-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'email': document.getElementById('email').value,
                }),
            }).then(resp => resp.json()).then(resp => {
                if (resp.error) {
                    setMessage(resp.error.message)
                } else {
                    document.getElementById('btn').innerHTML = 'Login';
                    document.getElementById('otp').disabled = false;
                    document.getElementById('login-loader').style.display = 'none';
                }
            });
        }
    }

    return <div
        className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
            <div className="row justify-content-center w-100">
                <div className="col-md-8 col-lg-6 col-xxl-3">
                    <div className="card mb-0">
                        <div className="card-body">
                            <span className="text-nowrap logo-img text-center d-block py-3 w-100">
                                <img src="https://www.elvesonthecloud.com/assets/img/hero-img.png" width="150" alt="" />
                            </span>
                            <p className="text-center">Login using otp</p>
                            <form onSubmit={(event) => otp(event)}>
                                <div className="mb-3">
                                    <label for="email" className="form-label">Email address</label>
                                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp" required />
                                </div>
                                <div className="mb-4">
                                    <label for="otp" className="form-label">One time password &nbsp;&nbsp;&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" id="otp-disabled"
                                            fill="none" stroke="red" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                            <path d="M12 8v4" /><path d="M12 16h.01" />
                                        </svg>
                                        <Spinner id="login-loader" />
                                    </label>
                                    <input type="password" className="form-control" id="otp" disabled required />
                                    <p className="text-primary fw-bold" id="msg">{message}</p>
                                </div>

                                <button type="submit"
                                    className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                                    id="btn">Request OTP
                                </button>
                                <div className="align-items-center justify-content-center">
                                    <p className="fs-4 mb-0 fw-bold">New to Elf Academy?</p>
                                    <p className="text-primary fw-bold">Sign in using an otp to create an account!</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}