import React from 'react';
import { useRoutes } from 'hookrouter';
import Enroll from "./components/Enroll";
import Profile from './components/Profile';
import GettingStarted from './components/GettingStarted';
import Learn from './components/Learn';
import Forum from './components/Forum';
import Home from './components/Home';
import Programme from './components/Programme';

export const MainContext = React.createContext();

function App(props) {

    function logOut() {
        let r = window.confirm('Are you sure?');
        if (r) {
            localStorage.removeItem('elfAcademyToken');
            window.location.reload();
        }
    }
    let [message, setMessage] = React.useState(null);
    let [pageTitle, setPageTitle] = React.useState('...');
    const headers = {
        'Authorization': `Token ${localStorage.getItem('elfAcademyToken')}`
    }
    const routes = {
        '/': () => <Home />,
        '/enroll': () => <Enroll />,
        '/programme': () => <Programme />,
        '/profile': () => <Profile />,
        '/getting-started': () => <GettingStarted />,
        '/learn': () => <Learn />,
        '/forum': () => <Forum />
    };
    const routeResults = useRoutes(routes);
    let contextVars = {
        'setMessage': setMessage,
        'setPageTitle': setPageTitle,
        'serverUrl': props.serverUrl,
        'headers': headers,
    }
    React.useEffect(() => {
        fetch(props.serverUrl + 'api/user/basic-info', {
            method: 'GET',
            headers: headers
        }).then(resp => resp.json()).then(resp => {
            localStorage.setItem('elfAcademyName', resp.data.name)
            localStorage.setItem('elfAcademyImg', resp.data.display_img)
            localStorage.setItem('elfAcademyEmail', resp.data.email)
            localStorage.setItem('elfAcademyprofileComplete', resp.data.profile_complete)
            localStorage.setItem('elfAcademyprofileLocked', resp.data.profile_locked)
            if (resp.data.message) {
                setMessage(resp.data.message)
            }
        });
    });
    return (
        <React.Fragment>
            <aside className="left-sidebar">
                <div>
                    <div className="brand-logo d-flex align-items-center justify-content-between">
                        <a href="#" className="text-nowrap logo-img">
                            <img src="https://www.elvesonthecloud.com/assets/img/hero-img.png" width="150" alt="" />
                        </a>
                        <div className="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                            <i className="ti ti-x fs-8"></i>
                        </div>
                    </div>
                    <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
                        <ul id="sidebarnav">
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                <span className="hide-menu">Home</span>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="\" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-layout-dashboard"></i>
                                    </span>
                                    <span className="hide-menu">Dashboard</span>
                                </a>
                            </li>
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                <span className="hide-menu">Programme</span>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="\enroll" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-user-plus"></i>
                                    </span>
                                    <span className="hide-menu">Enroll</span>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="\getting-started" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-player-play"></i>
                                    </span>
                                    <span className="hide-menu">Getting started</span>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="\programme" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-device-laptop"></i>
                                    </span>
                                    <span className="hide-menu">Learn</span>
                                </a>
                            </li>
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                <span className="hide-menu">Project</span>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="#" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-info-circle"></i>
                                    </span>
                                    <span className="hide-menu">Info</span>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="#" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-brand-git"></i>
                                    </span>
                                    <span className="hide-menu">Respository</span>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="#" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-list-check"></i>
                                    </span>
                                    <span className="hide-menu">Tasks</span>
                                </a>
                            </li>
                            <li className="nav-small-cap">
                                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                <span className="hide-menu">Other</span>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="\forum" aria-expanded="false">
                                    <span>
                                        <i className="ti ti-messages"></i>
                                    </span>
                                    <span className="hide-menu">Forum</span>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" href="#" onClick={() => alert('Coming soon!')} aria-expanded="false">
                                    <span>
                                        <i className="ti ti-file-invoice"></i>
                                    </span>
                                    <span className="hide-menu">Resume</span>
                                </a>
                            </li>
                            <br />
                            <br />
                        </ul>
                    </nav>
                </div>
            </aside>
            <div className="body-wrapper">
                <header className="app-header">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item d-block d-xl-none">
                                <a className="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="#">
                                    <i className="ti ti-menu-2"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                &nbsp;
                                {message && <div className="alert alert-warning" role="alert">
                                    {message}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span onClick={() => setMessage(null)} className="btn btn-sm btn-danger"><i className="ti ti-square-x"></i></span>
                                </div>}
                            </li>
                        </ul>
                        <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                <a href="#" onClick={() => alert('Call us at +91 6238559523')}
                                    className="btn btn-primary">Contact us
                                </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <code className="text-primary fw-bold">Hi {localStorage.getItem('elfAcademyName')?.split(' ')[0]}!</code>
                                <li className="nav-item dropdown">
                                    <a className="nav-link nav-icon-hover" href="#" id="drop2" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <img src={localStorage.getItem('elfAcademyImg')} alt="" width="35" height="35" className="rounded-circle" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                        <div className="message-body">
                                            <a href="\profile" className="d-flex align-items-center gap-2 dropdown-item">
                                                <i className="ti ti-user fs-6"></i>
                                                <p className="mb-0 fs-3">Profile</p>
                                            </a>
                                            <a href="#" className="d-flex align-items-center gap-2 dropdown-item">
                                                <i className="ti ti-file-certificate fs-6"></i>
                                                <p className="mb-0 fs-3">Documents</p>
                                            </a>
                                            <span onClick={() => logOut()} className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                <div className="container-fluid">
                    <h5 className="card-title fw-semibold mb-4 text-center">{pageTitle}</h5>
                    <MainContext.Provider value={contextVars}>
                        {routeResults}
                    </MainContext.Provider>
                </div>
            </div>
        </React.Fragment>
    );
}

export default App;
